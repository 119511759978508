import React from "react";
import "./services-video-popup.scss";
import { Link } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";

const ServicesVideoPopup = ({ closePopup, }) => {
  return (
    <div className={`services-video-popup`}>

      <div className="inner-sect">
        <iframe
          src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fmassagenowatl%2Fvideos%2F664256909285607%2F&show_text=false&width=560&t=0"
          
          style={{ border: 'none', overflow: 'hidden', borderRadius: "12px" }}
          scrolling="no"
          frameBorder="0"
          allowFullScreen={true}
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        ></iframe>

        <div className="icon-box" onClick={() => closePopup(false)}>
          <RxCross2 className="icon" />
        </div>
      </div>
    </div>
  );
};

export default ServicesVideoPopup;
