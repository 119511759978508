import React from "react";
import "./special-offers.scss";
import specialOffersMain1 from "../../assets/images/special-offers-main.png";
import specialOffersMain2 from "../../assets/images/special-offer-new-img.jpg";
import Helmet from "../../components/helmet/Helmet";
import CommonSection from "../../components/common-section/CommonSection";

const SpecialOffers = () => {
  return (
    <>
      <Helmet
        title={"special-offers"}
        canonical={"https://www.massagenow-atl.com/special-offers"}
      />
      <CommonSection title={"Special Offers"} />

      <div class="special-offers">
        <img className="main-img" src={specialOffersMain1} alt="special-offer" />
        <h4 class="title">MASSAGE NOW - ROMANTIC COUPLES MASSAGE SPECIAL</h4>
        <div class="content">
          <ul>
            <li class="para1">$20 Off: Monday-Thursday</li>
            <li class="para2">When You Mention This Promotion</li>
            <li class="para2">Cannot be combined with any other promotion</li>
          </ul>
          <button>
            <a href="tel:+16783880866">Book Now</a>
          </button>
        </div>
      </div>

      <div class="special-offers">
        <img className="main-img" src={specialOffersMain2} alt="special-offer" />
        <h4 className="title">MASSAGE NOW - WELCOME FIRST-TIME VISITORS</h4>
        <p className="second-title">$20* Off any massage one hour or longer</p>
        <div class="content">
          <ul>
            <li class="para1">
            Valid Monday - Friday. First time visitors only. Cannot be combined with any other promotion. Must mention promotion when checking in.
            </li>
            {/* <li class="para2">When You Mention This Promotion</li> */}
          </ul>
          <button>
            <a href="tel:+16783880866">Book Now</a>
          </button>
        </div>
      </div>
    </>
  );
};

export default SpecialOffers;
